@font-face {
    font-family: "KPMG";
    src: local("KPMG Light"), local("KPMG-Light"),
        url("./fonts/KPMG-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "KPMG";
    src: local("KPMG Extralight Italic"), local("KPMG-ExtralightItalic"),
        url("./fonts/KPMG-ExtralightItalic.woff2") format("woff2");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "KPMG";
    src: local("KPMG Thin Italic"), local("KPMG-ThinItalic"),
        url("./fonts/KPMG-ThinItalic.woff2") format("woff2");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "KPMG";
    src: local("KPMG Light Italic"), local("KPMG-LightItalic"),
        url("./fonts/KPMG-LightItalic.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "KPMG";
    src: local("KPMG Extralight"), local("KPMG-Extralight"),
        url("./fonts/KPMG-Extralight.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "KPMG";
    src: local("KPMG Thin"), local("KPMG-Thin"),
        url("./fonts/KPMG-Thin.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
